function normalize(currencyCode, maxLength = 20) {
  if(!currencyCode) return "";

  // Standard currency code
  if(currencyCode.length === 3 && currencyCode.trim().toLowerCase() !== 'xrp')
    return currencyCode.trim()

  // Hex currency code
  if(currencyCode.match(/^[a-fA-F0-9]{40}$/) && !isNaN(parseInt(currencyCode, 16))){
    const hex = currencyCode.toString().replace(/(00)+$/g, '')
    // Old demurrage: https://xrpl.org/demurrage.html
    if(hex.startsWith('01'))
      return demurrage2utf8(currencyCode);

    // XLS-16d NFT Metadata using XLS-15d Concise Transaction Identifier
    // https://github.com/XRPLF/XRPL-Standards/discussions/37
    if(hex.startsWith('02')){
      const xlf15d = Buffer.from(hex, 'hex').slice(8).toString('utf-8').slice(0, maxLength).trim()
      if(xlf15d.match(/[a-zA-Z0-9]{3,}/) && xlf15d.toLowerCase() !== 'xrp')
        return xlf15d
    }

    // Alphanumeric code, 3+ characters long
    const decodedHex = Buffer.from(hex, 'hex').toString('utf-8').slice(0, maxLength).trim()
    if(decodedHex.match(/[a-zA-Z0-9]{3,}/) && decodedHex.toLowerCase() !== 'xrp')
      return decodedHex
  }

  return "";
}

function demurrage2utf8(dcode){
  let bytes = Buffer.from(dcode, "hex")
  let code = String.fromCharCode(bytes[1]) + String.fromCharCode(bytes[2]) + String.fromCharCode(bytes[3]);
  let interest_start = (bytes[4] << 24) + (bytes[5] << 16) + (bytes[6] <<  8) + (bytes[7]);
  let interest_period = bytes.readDoubleBE(8);

  // By convention, the interest/demurrage rules use
  // fixed number of seconds per year (31536000),
  // not adjusted for leap days or leap seconds
  const year_seconds = 31536000;
  let interest_after_year = Math.pow(Math.E,
    (interest_start+year_seconds - interest_start) / interest_period)
  let interest = (interest_after_year * 100) - 100;

  return(`${code} (${interest}% pa)`)
}

module.exports = {
  normalize
}
