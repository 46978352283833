<template>
  <div id="app">
    <ResultModal />
    <SwellAboutModal />

    <b-navbar id="topbar">
      <b-navbar-brand><a href="/">TxF.actor</a></b-navbar-brand>
      <b-navbar-nav><FullScreen /></b-navbar-nav>
      <b-navbar-nav><PlayPauseToggle /></b-navbar-nav>
      <b-navbar-nav><BackgroundAudio :src="require('@/assets/desert-voices.mp3')" /></b-navbar-nav>
      <b-navbar-nav v-b-modal.swell_about_modal>About</b-navbar-nav>
    </b-navbar>

    <TXLoader />
    <SwellTXViewer />
  </div>
</template>

<script>
import ResultModal from './components/ResultModal'
import SwellAboutModal from './components/SwellAboutModal'
import FullScreen from './components/FullScreen'
import PlayPauseToggle from './components/PlayPauseToggle'
import BackgroundAudio from './components/BackgroundAudio'
import TXLoader from './components/TXLoader'
import SwellTXViewer from './components/SwellTXViewer'

export default {
  name: 'App',

  components : {
    ResultModal,
    SwellAboutModal,
    FullScreen,
    PlayPauseToggle,
    BackgroundAudio,
    SwellTXViewer,
    TXLoader
  }
}
</script>

<style scoped>
#app{
  height: 100%;
  display: flex;
  flex-direction: column;
}

#topbar{
  background-color: #35343c;
  font-weight: bold;
  height: 40px;
}

#topbar .navbar-brand a{
  color: #f44958;
  font-style: italic;
  font-size: 1.5rem;
}

#topbar .navbar-brand a:hover{
  text-decoration: none;
}

#topbar .navbar-nav{
  color: #d9e3ff;
  font-size: 1.25rem;
}

#topbar .navbar-nav:last-of-type{
  margin-left: 10px;
}

#topbar .navbar-nav:first-of-type{
  margin-left: auto;
}
</style>
