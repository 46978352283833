import * as Three from 'three'

export default {
  capitalize (str) {
    return str[0].toUpperCase() + str.slice(1);
  },

  random_item(array){
    return array[Math.floor(Math.random()*array.length)];
  },

  traverse_obj(obj, cb){
    cb(obj);
    for(let c = 0; c < obj.children.length; ++c){
      this.traverse_obj(obj.children[c], cb);
    }
  },

  clone_gltf (gltf){
    const clone = {
      animations: gltf.animations,
      scene: gltf.scene.clone(true)
    };

    const skinnedMeshes = {};

    gltf.scene.traverse(node => {
      if (node.isSkinnedMesh) {
        skinnedMeshes[node.name] = node;
      }
    });

    const cloneBones = {};
    const cloneSkinnedMeshes = {};

    clone.scene.traverse(node => {
      if(node.material)
        node.material = node.material.clone();

      if (node.isBone) {
        cloneBones[node.name] = node;
      }

      if (node.isSkinnedMesh) {
        cloneSkinnedMeshes[node.name] = node;
      }
    });

    for (let name in skinnedMeshes) {
      const skinnedMesh = skinnedMeshes[name];
      const skeleton = skinnedMesh.skeleton;
      const cloneSkinnedMesh = cloneSkinnedMeshes[name];

      const orderedCloneBones = [];

      for (let i = 0; i < skeleton.bones.length; ++i) {
        const cloneBone = cloneBones[skeleton.bones[i].name];
        orderedCloneBones.push(cloneBone);
      }

      cloneSkinnedMesh.bind(
        new Three.Skeleton(orderedCloneBones, skeleton.boneInverses),
        cloneSkinnedMesh.matrixWorld);
    }

    return clone;
  }
}
