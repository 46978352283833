module.exports = {
  http_port  : 3000,
  https_port : 3001,

  mock_txs : 0,

  blockchains : ['btc', 'eth', 'xrp'],

  retention : {
    blockchain : 25
  },

  prices : {
    xrp : "https://rate.sx/1XRP",
    xlm : "https://rate.sx/1XLM"
  }
}
