const DROPS_PER_XRP = 1_000_000;

const { normalize } = require('../vendor/xrp-currencies')

function convert_iou(iou){
  iou.currency = normalize(iou.currency)
  iou.value = parseFloat(iou.value);
  return iou;
}

module.exports = {
  DROPS_PER_XRP,

  convert(tx){
    let converted = {
      blockchain : 'xrp',
      timestamp : new Date(Date.now())
    }

    tx = tx.raw;

    converted.hash = tx.transaction.hash;
    converted.fee = parseInt(tx.transaction.Fee)/DROPS_PER_XRP;
    converted.type = tx.transaction.TransactionType;
    converted.sender = tx.transaction.Account;
    converted.recipient = tx.transaction.Destination;
    converted.block = tx.ledger_index || tx.transaction.ledger_index;
    converted.orig = tx;

    if(typeof(tx.transaction.Amount) == 'string'){
      converted.currency = 'xrp'
      converted.amount = parseInt(tx.transaction.Amount) / DROPS_PER_XRP;
    }
    else if(typeof(tx.transaction.Amount) == 'object'){
      converted.currency = tx.transaction.Amount.currency;
      converted.amount = parseInt(tx.transaction.Amount.value);
      converted.issuer = tx.transaction.Amount.issuer;
    }

    converted.meta = tx.meta;
    converted.nodes = tx.meta.AffectedNodes;
    converted.money_flow = [];
    converted.offers = []

    for(let n = 0; n < converted.nodes.length; ++n){
      const op = Object.keys(converted.nodes[n])[0];
      const detail = converted.nodes[n][op]
      const type = detail['LedgerEntryType'];
      const fields_id = detail['FinalFields'] ? 'FinalFields' : 'NewFields';
      const fields = detail[fields_id];

      if(type == 'RippleState'){
        const ciou = convert_iou(fields.Balance);
        converted.money_flow.push(ciou);

      }else if(type == "Offer"){
        let asset, xrp;
        if(typeof(fields.TakerGets) == "string"){
          xrp = parseInt(fields.TakerGets)/DROPS_PER_XRP;
          asset = convert_iou(fields.TakerPays);
        }else{
          xrp = parseInt(fields.TakerPays)/DROPS_PER_XRP;
          asset = convert_iou(fields.TakerGets);
        }

        const price = asset.value / xrp;

        if(price && price > 0)
          converted.offers.push({asset, xrp, price});
      }
    }

    return converted;
  }
}
