import { createStore } from 'vuex'
const Queue = require("./queue").Queue;

export const store = createStore({
  state () {
    return {
      paused : false,

      loading : "Loading...",

      blocks : {
        btc : null,
        eth : null,
        xrp : null
      },

      txs: {
        btc : new Queue(),
        eth : new Queue(),
        xrp : new Queue()
      },

      tx_stats : {
      },

      global_result : {
        id : null,
        message : null,
        is_error : false,
        is_warning : false,
      },
    }
  },

  mutations : {
    set_paused(state, paused) {
      state.paused = paused;
    },

    set_blocks(state, blocks){
      state.blocks = Object.assign({}, blocks)
    },

    set_txs(state, txs) {
      state.txs = Object.assign({}, txs);
    },

    set_tx_stats(state, tx_stats) {
      state.tx_stats = Object.assign({}, tx_stats);
    },

    set_loading(state, loading){
      state.loading = loading;
    },

    set_global_result(state, result){
      state.global_result.id         = result.id;
      state.global_result.message    = result.message;
      state.global_result.is_error   = result.is_error;
      state.global_result.is_warning = result.is_warning;
    },

    clear_global_result(state){
      state.global_result.id         = null;
      state.global_result.message    = null;
      state.global_result.is_error   = false;
      state.global_result.is_warning = false;
    }
  }
})


