const ethers = require("ethers");
const xrpl = require("xrpl");

import config from '../config/config'
import txf_config from '../config/txf.actor'
const safe_convert = require("../../backend/convert").safe_convert
const Queue = require("../queue").Queue;

let txf_id = 0;

export default {
  methods : {
    get_txs : async function () {
      this.server_txs ||= {
        btc : new Queue(),
        eth : new Queue(),
        xrp : new Queue()
      }

      this.blocks ||= {
        btc : null,
        eth : null,
        xrp : null
      }

      this.tx_stats ||= {
      }

      this.get_btc_txs_safe();
      this.get_eth_txs_safe();
      this.get_xrp_txs_safe();
    },

    update_block(block, blockchain){
      this.blocks[blockchain] = block
      this.$store.commit('set_blocks', this.blocks)
    },

    register_txs (txs, blockchain){
      let queue = this.server_txs[blockchain];
      txs.forEach((tx) => {
        tx.txf_id = txf_id++;
        queue.enqueue(tx)
      });

      if(queue.length > txf_config.retention.blockchain)
        for(let t = 0; t < queue.length - txf_config.retention.blockchain; ++t)
          queue.dequeue();
      this.$store.commit('set_txs', this.server_txs);
    },

    update_tx_stats(num, category){
      this.tx_stats[category] ||= 0
      this.tx_stats[category]  += num
      this.$store.commit('set_tx_stats', this.tx_stats)
    },

    get_btc_txs : async function(){
      const block_uri = config.network.btc.latest_block;
      let block = await this.$htttp().get(block_uri,
        {timeout: config.request}
      )
      if(this.btc_block && this.btc_block.hash == block.body.hash)
        return;
      this.btc_block = block.body;

      let txs_uri = config.network.btc.block_n.replace("HASH", this.btc_block.hash);
      let txs = (await this.$htttp().get(txs_uri)).body.tx;
      let converted = [];
      for(let t = 0; t < txs.length; ++t){
        const ctx = safe_convert(txs[t], 'btc');
        if(!ctx.sender || !ctx.recipient || ctx.amount < 0.05)
          continue;
        converted.push(ctx)
      }
      this.register_txs(converted, 'btc');
    },

    get_btc_txs_safe : async function(){
      try{
        await this.get_btc_txs()

      }catch(err){
        console.log(err)
      }
    },

    get_eth_txs : async function(){
      if(this.eth_provider && this.eth_connected)
        return;

      const connect = () => {
        this.eth_provider = new ethers.providers.JsonRpcProvider({
          url : config.network.eth,
          timeout : config.request
        });

        this.eth_provider.on("error", async function(){
          this.eth_connected = false;
          throttle_connection.bind(this)();
        }.bind(this))

        this.eth_provider.on("close", async function(){
          this.eth_connected = false;
          throttle_connection.bind(this)();
        }.bind(this))

        this.eth_provider.on("block", function(number){
          this.eth_provider.getBlockWithTransactions(number)
            .then(function(block){
              this.eth_block = block;

              let txs = this.eth_block.transactions;
              let converted = [];
              for(let t = 0; t < txs.length; ++t){
                let ctx = safe_convert(txs[t], 'eth');
                if(!ctx.sender || !ctx.recipient || ctx.amount < 0.005) continue;
                converted.push(ctx)
              }
              this.register_txs(converted, 'eth');

            }.bind(this))
        }.bind(this))

        this.eth_connected = true;
      }

      const throttle_connection = () => {
        if(this.eth_connecting) return;
        this.eth_connecting = true;

        setTimeout(function(){
          this.eth_connecting = false;
          connect.bind(this)();
        }.bind(this), 1000);
      };

      connect();
    },

    get_eth_txs_safe : async function(){
      try{
        await this.get_eth_txs()

      }catch(err){
        console.log(err)
      }
    },

    get_xrp_txs : async function(){
      if(this.xrp_client && this.xrp_client.isConnected)
        return;

      if(this.xrp_client)
        this.xrp_client.disconnect();

      this.xrp_client = new xrpl.Client(config.network.xrp)
      await this.xrp_client.connect()

      this.xrp_client.on('transaction', (tx) => {
        this.update_block(tx.ledger_index, 'xrp');
        this.update_tx_stats(1, tx.transaction.TransactionType);

        if(tx.transaction.TransactionType != "Payment") return;
        const ctx = safe_convert({raw : tx}, 'xrp');
        if(!ctx.sender || !ctx.recipient || ctx.amount < 0.005) return;
        this.register_txs([ctx], 'xrp');
      });

      await this.xrp_client.request({
        command: 'subscribe',
        streams: ['transactions']
      })
    },

    get_xrp_txs_safe : async function(){
      try{
        await this.get_xrp_txs()

      }catch(err){
        console.log(err)
      }
    }
  }
}
