import {Clock} from 'three'
import config from '../../config/config'

const fps_samples = 50;
const clock = new Clock()

function offset_uv(model, diff){
  let offset = model.material.map.offset;
  offset.y += diff;
  model.material.map.offset.set(offset.x, offset.y);
}

export default{
  data(){
    return {
      frame : 0,
      delta : 0,
      animating : false,
      fpss : []
    }
  },

  computed : {
    paused (){
      return this.$store.state.paused;
    },

    fps () {
      const sum = this.fpss.reduce((t, fps) => t + fps, 0);
      const avg = sum / fps_samples;
      return avg;
    },

    laggy (){
      return this.fps < 25;
    }
  },

  watch : {
    is_loaded(){
      if(this.animating)
        this.animate();
    }
  },

  methods : {
    offset_assemblies(){
      if(!this.loaded.assembly) return;
      offset_uv(this.btc_model, config.uv_speeds.btc);
      offset_uv(this.eth_model, config.uv_speeds.eth);
      offset_uv(this.xrp_model, config.uv_speeds.xrp);
    },

    animate_people(){
      if(!this.loaded.person1 || !this.loaded.person2) return;

      this.person1Mixer.update(this.delta);
      this.person2Mixer.update(this.delta);
    },

    animate: function() {
      if(!this.animating) return;
      requestAnimationFrame(this.animate);

      ++this.frame;
      this.delta = clock.getDelta();
      this.fpss[this.frame%fps_samples] = 1/this.delta;

      this.animate_people();

      if(!this.paused){
        this.offset_assemblies();
        this.generate();
        this.process_generated();
      }

      this.renderer.render(this.scene, this.camera);
      this.stats.update();
    },

    start_animate (){
      this.animating = true;
      if(this.is_loaded)
        this.animate();
    },

    stop_animate(){
      this.animating = false;
    }
  }
}
