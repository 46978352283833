import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import VueResource from 'vue-resource'
import { Vue3Mq } from "vue3-mq";
import Vue3TouchEvents from "vue3-touch-events";
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

///

import App from './App.vue'
import Swell from './Swell.vue'
import MoneyFlow from './MoneyFlow.vue'
import ArbitrageChart from './ArbitrageChart.vue'

import { store } from './store'
import HTTTP from './plugins/htttp'

import("../public/common.css")
import("../public/fonts.css")

const routes = [
  { path: '/', component: App },
  { path: '/swell', component: Swell },
  { path: '/moneyflow', component: MoneyFlow },
  { path: '/arbitrage', component: ArbitrageChart },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

let app = createApp({template : '<router-view></router-view>'})

//app.config.errorHandler = () => null;
//app.config.warnHandler = () => null;

app.use(BootstrapVue)
   .use(router)
   .use(store)
   .use(VueResource)
   .use(HTTTP)
   .use(Vue3Mq)
   .use(Vue3TouchEvents)

app.mount('#app')
