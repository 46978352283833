export class Queue {
  constructor(a) {
    this.elements = {};
    this.head = 0;
    this.tail = 0;
    if(a) a.forEach(ai => this.enqueue(ai));
  }
  enqueue(element) {
    this.elements[this.tail] = element;
    this.tail++;
  }
  dequeue() {
    const item = this.elements[this.head];
    delete this.elements[this.head];
    this.head++;
    return item;
  }
  peek() {
    return this.elements[this.head];
  }
  get length() {
    return this.tail - this.head;
  }
  get isEmpty() {
    return this.length === 0;
  }
  some(cb){
    for(let i = this.head; i < this.tail; ++i)
      if(cb(this.elements[i]))
        return true;
    return false;
  }
}
