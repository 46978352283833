<template>
  <div>
    <b-button @click="toggle">
      <img :src="playPauseImg" />
    </b-button>
  </div>
</template>

<script>
export default{
  name : 'PlayPauseToggle',

  computed : {
    paused : {
      get(){
        return this.$store.state.paused;
      },

      set(v){
        this.$store.commit('set_paused', v);
      }
    },

    playPauseImg(){
      return this.paused ?
        require("../assets/images/play.svg") :
        require("../assets/images/pause.svg");
    }
  },

  methods : {
    toggle (){
      this.paused = !this.paused;
    }
  }
}
</script>

<style scoped>
.btn-secondary,
.btn-secondary:hover{
  box-shadow: none;
  background-color: #35343c;
  border-radius: 10px;
  border: none;
  padding: 0px 5px 3px 5px;
}

img{
  width: 16px;
  height: 16px;
}
</style>
