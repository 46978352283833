import {GLTFLoader} from 'three/addons/loaders/GLTFLoader.js'
//import { FontLoader } from 'three/addons/loaders/FontLoader.js'
import * as Three from 'three'

//const config = require('../../config/config')
const util = require("../../util")
//const random_item = util.default.random_item;
//const Queue = require("../../queue").Queue;

import { Water } from 'three/addons/objects/Water.js';
import { Sky } from 'three/addons/objects/Sky.js';


export default {
  data(){
    return {
      sun_params : {
        color : 0xDAD524,
        azimuth : 220,
        elevation : 0,
        before_noon : true,
      },

      loaded : {
        water : false,
        sky : false,
        land : false,
        camel : false
      }
    }
  },

  computed : {
    loading_msg(){
      return this.$store.state.loading;
    },

    is_loaded(){
      return this.loaded.water &&
             this.loaded.sky   &&
             this.loaded.land  &&
             this.loaded.camel;
    },
  },

  methods : {
    // Load water
    load_water (){
      if(this.loaded.water) return;

      const waterGeometry = new Three.PlaneGeometry( 10000, 10000 );

			this.water = new Water(waterGeometry, {
        textureWidth: 512,
        textureHeight: 512,
        waterNormals: new Three.TextureLoader().load(require('@/assets/images/waternormals.jpg'), function ( texture ) {
          texture.wrapS = texture.wrapT = Three.RepeatWrapping;
        }),
        sunDirection: new Three.Vector3(),
        sunColor: this.sun_params.color,
        waterColor: 0x001e0f,
        distortionScale: 3.7,
        fog: this.scene.fog !== undefined
      });
      this.water.rotation.x = - Math.PI / 2;
      this.scene.add(this.water);
      this.loaded.water = true;
    },

    anim_water (){
      this.water.material.uniforms[ 'time' ].value -= 1.0 / 60.0;
    },

    // Load sky
    load_sky (){
      if(this.loaded.sky) return;

      this.sun_pos = new Three.Vector3();
      this.sun = new Three.DirectionalLight(this.sun_params.color, 0.5);
      this.sun.castShadow = true;
      this.sun.shadow.camera.far = 2500;
      this.sun.shadow.camera.left = -1000;
      this.sun.shadow.camera.bottom = -1000;
      this.sun.shadow.camera.right = 1000;
      this.sun.shadow.camera.top = 1000;
      this.scene.add(this.sun);

      this.sky = new Sky();
      this.sky.scale.setScalar(10000);
      this.sky.castShadow = true;
      //this.scene.add(this.sky);

      this.skyUniforms = this.sky.material.uniforms;
      this.skyUniforms[ 'turbidity' ].value = 2;
      this.skyUniforms[ 'rayleigh' ].value = 1;
      this.skyUniforms[ 'mieCoefficient' ].value = 0.005;
      this.skyUniforms[ 'mieDirectionalG' ].value = 0.8;

      this.pmremGenerator = new Three.PMREMGenerator(this.renderer);
      this.sceneEnv = new Three.Scene();
      this.sceneEnvTarget = undefined;
      this.update_sun();

      this.loaded.sky = true;
    },

    // Update sun
    update_sun(){
      const phi = Three.MathUtils.degToRad(90 - this.sun_params.elevation);
      const theta = Three.MathUtils.degToRad(this.sun_params.azimuth);

      this.sun_pos.setFromSphericalCoords( 1, phi, theta );

      this.sky.material.uniforms['sunPosition'].value.copy(this.sun_pos);
      this.water.material.uniforms['sunDirection'].value.copy(this.sun_pos).normalize();

      this.water.sunDirection = this.sun_pos;
      this.sun.position.copy(this.sun_pos);
      this.sun.position.multiplyScalar(1000);

      if(this.sceneEnvTarget !== undefined) this.sceneEnvTarget.dispose();

      this.sceneEnv.add( this.sky );
      this.sceneEnvTarget = this.pmremGenerator.fromScene( this.sceneEnv );
      this.scene.add( this.sky );
      this.scene.environment = this.sceneEnvTarget.texture;
    },

    anim_day_night(delta){
      if(this.sun_params.before_noon){
        this.sun_params.elevation += delta * 3;
        if(this.sun_params.elevation >= 90){
          this.sun_params.elevation = 90;
          this.sun_params.before_noon = false;
          this.sun_params.azimuth = 30;
        }

      }else{
        this.sun_params.elevation -= delta * 3;
        if(this.sun_params.elevation <= 0){
          this.sun_params.elevation = 0;
          this.sun_params.before_noon = true;
          this.sun_params.azimuth = 220;
        }
      }

      this.update_sun();
    },

    // Load 3D land
    load_land (){
      if(this.loaded.land) return;

      const gltf_asset = require('@/assets/blender/swell-sand.glb');
      const gltfLoader = new GLTFLoader();
      gltfLoader.load(gltf_asset,
        (gltf) => {
          this.markers = [];

          let model = gltf.scene;
          util.default.traverse_obj(model, (o) => {
            if(o.name != "Sand") // XXX: exclude sand
              o.castShadow = true;

            o.receiveShadow = true;

            if(o.name == 'DNPLogo')
              this.dnpLogo = o;

            if(o.name.substr(0, 6) == "marker")
              this.markers.push(o);
          })

          this.land = model;

          this.land.position.set(0, 50, 0);
          this.land.scale.set(25, 25, 25);
          this.scene.add(this.land);
          this.$store.commit('set_loading', 'Loaded Land...');
          this.loaded.land = true;
        }
      );
    },

    // Load camel
    load_camel(){
      if(this.loaded.camel) return;

      const gltf_asset = require('@/assets/blender/camel.glb');
      const gltfLoader = new GLTFLoader();
      gltfLoader.load(gltf_asset,
        (gltf) => {
          this.camel = gltf;
          this.$store.commit('set_loading', 'Loaded Camels...');
          this.loaded.camel = true;
        });
    },

    // Load all constructs
    load (){
      this.load_water();
      this.load_sky();
      this.load_land();
      this.load_camel();
    }
  }
}
