<template>
  <b-modal id="about_modal"
           ref="about_modal"
           ok-title="Close"
           ok-only
           size="xl"
           centered>
    <ModalWrapper>
      <h1>TXF.ACTOR</h1>
      <h3>A <a href="https://devnullprod.com" target="_blank">Dev Null Productions</a> Service</h3>
      <hr/>

      <p>
      You are viewing live payments of interest from the <b>BTC</b>, <b>ETH</b>, and <b>XRP</b> blockchains that have been filtered by <b>Block Trac</b>.
      </p>

      <p>
      Use <a href="https://blocktr.ac" target="_blank">Block Trac</a> to get notification of any live blockchain transactions you are interested in, whether it be your wallet, your favorite NFT, or anything else!
      </p>

      <hr/>
      <p v-if="desktop">
        <i>Click and drag</i> to look around!
      </p>

      <p v-if="mobile">
        <i>Touch and drag</i> to look around!
      </p>

    </ModalWrapper>
  </b-modal>
</template>

<script>
import ModalWrapper from './ModalWrapper'

export default{
  name : "AboutModal",

  inject : ["mq"],

  components : {
    ModalWrapper
  },

  computed : {
    mobile(){
      return ['xs', 'sm', 'md'].includes(this.mq.current)
    },

    desktop(){
      return !this.mobile;
    }
  }
}
</script>

<style>
#about_modal {
  text-align: center;
  font-weight: bold;
  font-size: 22px;
}

#about_modal___BV_modal_content_{
  border-radius: 25px;
}

#about_modal___BV_modal_header_{
  display: none;
}

#about_modal___BV_modal_body_{
  padding-top: 25px;
}

#about_modal___BV_modal_body_ img{
  margin-bottom: 25px;
}

#about_modal___BV_modal_footer_{
  border: none;
}

#about_modal___BV_modal_footer_ button{
  width: 100%;
  font-weight: bold;
}
</style>
