import {Clock} from 'three'

const fps_samples = 50;
const clock = new Clock()

export default{
  data(){
    return {
      frame : 0,
      delta : 0,
      animating : false,
      fpss : []
    }
  },

  computed : {
    paused (){
      return this.$store.state.paused;
    },

    fps () {
      const sum = this.fpss.reduce((t, fps) => t + fps, 0);
      const avg = sum / fps_samples;
      return avg;
    },

    laggy (){
      return this.fps < 25;
    }
  },

  watch : {
    is_loaded(){
      if(this.animating)
        this.animate();
    }
  },

  methods : {
    animate: function() {
      if(!this.animating) return;
      requestAnimationFrame(this.animate);

      ++this.frame;
      this.delta = clock.getDelta();
      this.fpss[this.frame%fps_samples] = 1/this.delta;

      if(!this.paused){
        this.anim_day_night(this.delta);
        this.anim_water();
        this.generate();
        this.process_generated();
      }

      this.renderer.render(this.scene, this.camera);
      this.stats.update();
    },

    start_animate (){
      this.animating = true;
      if(this.is_loaded)
        this.animate();
    },

    stop_animate(){
      this.animating = false;
    }
  }
}
