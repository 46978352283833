<template>
  <div id="tx_viewer_container">
    <div id="tx_viewer"
         ref="tx_viewer"
         @mousemove="mouseMove"
         @click="click" v-touch:tap="tapped"></div>

    <div v-show="laggy" id="alert">
      <b-alert show variant="danger">
        <b>Slow performance detected. (<i>{{fps.toFixed(0)}} FPS</i>) Please make sure you have installed all required software updates and drivers.</b>
      </b-alert>
    </div>

    <Transition name="fade">
      <div v-show="!is_loaded" id="splash">
        <b-spinner variant="light"/>
        <div id="loading_msg">{{loading_msg}}</div>
      </div>
    </Transition>

    <Transition name="fade">
      <div v-show="is_loaded && reorientation_needed" id="reorientation">
        <img src="../assets/images/rotate-device.gif" />
      </div>
    </Transition>
  </div>
</template>

<script>
import input from "./TXViewer/input"
import init from "./TXViewer/init"
import load from "./TXViewer/load"
import data from "./TXViewer/data"
import anim from "./TXViewer/anim"

export default {
  name: 'TXViewer',

  inject: ["mq"],

  mixins : [
    input,
    init,
    anim,
    data,
    load,
  ],

  mounted () {
    this.init();
    this.init_input();
    this.init_data();
    this.load();
    this.start_animate();
  },

  unmounted(){
    this.cleanup();
    this.stop_animate();
  }

  // TODO cleanup scene & renderer on dismount
}
</script>

<style scoped>
#tx_viewer_container{
  position: fixed;
  top: 40px;
  height: calc(100% - 40px);
  width: 100%;
  display: flex;
  align-items: center;
  background-color: black;
}

#tx_viewer{
  min-width: 75%;
  width: 100%;
  height: 100%;
  background-color: white;
  margin-top: auto;
  margin-bottom: auto;
}

#alert{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

#alert .alert{
  border-radius: 0;
}

#splash{
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: gray;
}

#loading_msg{
  margin-top: 5px;
  font-weight: bold;
}

#reorientation{
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.4);
}
</style>
