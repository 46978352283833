import * as Three from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import Stats from 'stats.js'

const config = require('../../config/config')

export default {
  computed : {
    reorientation_needed(){
      return ['xs', 'sm', 'md'].includes(this.mq.current) &&
             window.innerHeight > window.innerWidth;
    }
  },

  methods : {
    init_stats() {
      if(this.stats)
        return;

      this.stats = new Stats();
      this.stats.setMode(0);

      this.stats.domElement.style.position = 'absolute';
      this.stats.domElement.style.left = '0';
      this.stats.domElement.style.top = '0';

      if(config.stats)
        document.body.appendChild(this.stats.domElement);
    },

    // Initialize scene
    init_scene(){
      this.scene = new Three.Scene();
      //this.scene.add(new Three.AxesHelper(500))
      //this.scene.add(new Three.GridHelper(100, 10))

      const light = new Three.DirectionalLight(0xffffff, 0.35)
      light.position.set(0, 25, 25);
      this.scene.add(light);
    },

    init_materials (){
      this.textMaterial = new Three.MeshBasicMaterial();
    },

    // Initialize render
    init_renderer(){
      this.container = document.getElementById('tx_viewer');

      this.renderer = new Three.WebGLRenderer();
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setSize(this.container.clientWidth, this.container.clientHeight);
      this.renderer.toneMappingExposure = Math.pow(1, 4.0);
      this.renderer.useLegacyLights = false;

      this.container.appendChild(this.renderer.domElement);
    },

    // Initialize camera and camera controls
    init_camera(){
      // Setup camera
      this.camera = new Three.PerspectiveCamera(70,
        this.container.clientWidth/this.container.clientHeight,
        0.1, 1000);
      this.camera.position.set(5, 10, 20)

      // Setup camera controls
      this.controls = new OrbitControls(this.camera, this.renderer.domElement)
      this.controls.enableDamping = true
      this.restrict_camera();

      // Add camera to scene
      this.scene.add(this.camera)
    },

    // Restrict camera controls
    restrict_camera(){
      this.controls.minPolarAngle = Math.PI/2.25;
      this.controls.maxPolarAngle = Math.PI/1.95;
      this.controls.maxAzimuthAngle = Math.PI/4;
      this.controls.minAzimuthAngle = -Math.PI/4;
      this.controls.minDistance = 25;
      this.controls.maxDistance = 50;
      this.controls.target.set(0, 5, -25);
      this.controls.update();
    },

    handle_resize(){
      this.resizeHandler = () => {
        this.camera.aspect =
          this.container.clientWidth/this.container.clientHeight;
        this.camera.updateProjectionMatrix();
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(
          this.container.clientWidth,
          this.container.clientHeight);
        this.restrict_camera();
      };
      window.addEventListener('resize', this.resizeHandler);
    },

    unhandle_resize(){
      if(this.resizeHandler){
        window.removeEventListener('resize', this.resizeHandler);
        this.resizeHandler = null;
      }
    },

    // Initialize all constructs
    init: function() {
      this.init_stats();
      this.init_scene();
      this.init_materials();
      this.init_renderer();
      this.init_camera();
      this.handle_resize();
    },

    ///

    cleanup (){
      this.unhandle_resize();

      this.scene = null;
      if(this.renderer)
        this.renderer.dispose();
    }
  }
}
