<template>
  <div id="tx_viewer_container">
    <div id="tx_viewer"
         ref="tx_viewer"
         @mousemove="mouseMove"
         @click="click" v-touch:tap="tapped"></div>

    <div v-show="laggy" id="alert">
      <b-alert show variant="danger">
        <b>Slow performance detected. (<i>{{fps.toFixed(0)}} FPS</i>) Please make sure you have installed all required software updates and drivers.</b>
      </b-alert>
    </div>

    <Transition name="fade">
      <div v-show="!is_loaded" id="splash">
        <b-spinner variant="light"/>
        <div id="loading_msg">{{loading_msg}}</div>
      </div>
    </Transition>

    <Transition name="fade">
      <div v-show="is_loaded && reorientation_needed" id="reorientation">
        <img src="../assets/images/rotate-device.gif" />
      </div>
    </Transition>

    <div id="swell_content" :class="mq.current" v-show="is_loaded && show_swell_content">
      <img id="swell_content_close" :src="require('@/assets/images/close-64.svg')"
        @click="show_swell_content = false; $bvModal.show('swell_about_modal')" />

      <div>
        <a href="https://devnullprod.com" target="_blank">
          <img id="dnp_logo" :class="mq.current" :src="require('@/assets/images/dnp-small.png')" />
        </a>
      </div>
      <div>
        <img id="swell_logo" :class="mq.current" :src="require('@/assets/images/ripple_swell.jpg')" />
      </div>
      <div class="text">Dubai, 2023</div>
    </div>

    <div id="xrpl_stats" :class="mq.current" v-show="is_loaded">
      <div style="text-align: center">
        <u>XRPL</u><span id="xrpl_loading" v-if="!have_block">: Loading <b-spinner small /></span>
      </div>

      <div v-if="have_block">
        Latest Block: {{block}}
      </div>

      <div v-if="have_block">
        <div v-for="(tx_category, i) in tx_categories" :key="'tx_category_' + i">
          {{tx_category}}: {{tx_stats[tx_category]}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import input from "./SwellTXViewer/input"
import init from "./SwellTXViewer/init"
import load from "./SwellTXViewer/load"
import data from "./SwellTXViewer/data"
import anim from "./SwellTXViewer/anim"

export default {
  name: 'TXViewer',

  inject: ["mq"],

  mixins : [
    input,
    init,
    anim,
    data,
    load,
  ],

  data () {
    return {
      show_swell_content : true
    }
  },

  computed : {
    have_block (){
      return !!this.block;
    },

    block (){
      return this.$store.state.blocks['xrp'];
    },

    tx_stats (){
      return this.$store.state.tx_stats;
    },

    tx_categories (){
      return Object.keys(this.tx_stats).sort();
    }
  },

  mounted () {
    this.init();
    this.init_input();
    this.init_data();
    this.load();
    this.start_animate();
  },

  unmounted(){
    this.cleanup();
    this.stop_animate();
  }

  // TODO cleanup scene & renderer on dismount
}
</script>

<style scoped>
#tx_viewer_container{
  position: fixed;
  top: 40px;
  height: calc(100% - 40px);
  width: 100%;
  display: flex;
  align-items: center;
  background-color: black;
}

#tx_viewer{
  min-width: 75%;
  width: 100%;
  height: 100%;
  background-color: white;
  margin-top: auto;
  margin-bottom: auto;
}

#alert{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

#alert .alert{
  border-radius: 0;
}

#splash{
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: gray;
}

#loading_msg{
  margin-top: 5px;
  font-weight: bold;
}

#reorientation{
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 2;
}

#swell_content{
  position: absolute;
  background: rgba(117,169,200,0.87);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 15px 50px;
  text-align: center;
  font-size: 2rem;
  border-radius: 15px;
}

#swell_content.md,
#swell_content.sm,
#swell_content.xs{
  font-size: 1.5rem;
}

#swell_content.md,
#swell_content.sm,
#swell_content.xs{
  padding: 3px 50px;
}

#swell_content_close{
  width: 32px;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

#swell_content div:last-child{
  margin: 25px;
  font-family: 'Lora', serif;
  font-weight: bold;
}

#swell_content.md div:last-child,
#swell_content.sm div:last-child,
#swell_content.xs div:last-child{
  margin: 5px;
}

#dnp_logo.md,
#dnp_logo.sm,
#dnp_logo.xs{
  width: 75px;
}

#swell_logo.md,
#swell_logo.sm,
#swell_logo.xs{
  width: 150px;
}

#xrpl_stats{
  position: absolute;
  top: 10%;
  right: 0%;
  background: rgba(117,169,200,0.87);
  padding: 25px;
  border-radius: 15px 0 0 15px;
}

#xrpl_stats.md,
#xrpl_stats.sm,
#xrpl_stats.xs{
  top: 5%;
}
</style>
