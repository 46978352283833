<template>
  <div>
    <b-button @click="request">
      <img src="../assets/images/expand.png" />
    </b-button>
  </div>
</template>

<script>
export default{
  name : 'FullScreen',

  methods : {
    request (){
      if(!document.fullscreenElement)
        document.documentElement.requestFullscreen();
      else if(document.exitFullscreen)
        document.exitFullscreen();
    }
  }
}
</script>

<style scoped>
.btn-secondary,
.btn-secondary:hover{
  box-shadow: none;
  background-color: #35343c;
  border-radius: 10px;
  border: none;
  padding: 0px 5px 3px 5px;
}

img{
  width: 16px;
  height: 16px;
}
</style>
