import { Vector3 } from 'three'
import SpriteText from 'three-spritetext';

const config = require('../../config/config')
const txfactor_config = require('../../config/txf.actor')

let Vector3Zero = new Vector3(0, 0, 0);

export default {
  data(){
    return {
      last_generated : {
        btc : {position : config.destroy + 1},
        eth : {position : config.destroy + 1},
        xrp : {position : config.destroy + 1},
      }
    }
  },

  computed : {
    txs() {
      return this.$store.state.txs;
    }
  },

  methods : {
    init_data (){
      if(!this.generated)
        this.generated = {};
    },

    should_generate(blockchain){
      const last = this.last_generated[blockchain];
      const max = config.box_interval;
      return last.position > max;
    },

    generate(){
      const now = new Date();

      txfactor_config.blockchains.forEach((b) => {
        if(!this.should_generate(b)) return;

        let btxs = this.txs[b];
        if(btxs.length == 0) return;

        const tx = btxs.dequeue();
        const generated = this.generate_one(tx, now);
        this.generated[tx.txf_id] = generated;
        this.last_generated[b] = generated;
      });
    },

    generate_one(tx, now){
      let pos = config.assembly_positions[tx.blockchain];

      // Create box
      let box = this.boxes.dequeue();
      //if(!box) return; // XXX: this should never occur, commenting for performance

      box.position.set(pos, 1, -25);
      box.name = "box-" + tx.txf_id;

      let textContent = tx.sender.substring(0,6) + " -> " +
                        tx.recipient.substring(0,6) + ": " +
                        tx.amount.toFixed(3);
      let text = new SpriteText(textContent, 0.5);
      let textScale = text.scale.clone();
      text.position.set(pos, 2, -25);
      text.scale.multiplyScalar(0.1);
      this.scene.add(text);

      // Return generated
      const generated = {
        tx, box, text, textScale,
        timestamp : now,
        position : -25
      };
      return generated;
    },

    process_generated(){
      Object.keys(this.generated).forEach((g) => {
        let gen = this.generated[g];
        if(gen.position > config.destroy){
          this.boxes.enqueue(gen.box);
          gen.text.removeFromParent();
          gen.text.geometry.dispose();
          delete this.generated[g];
          return;
        }

        let bpos = gen.box.position;
        let tpos = gen.text.position;
        let diff = config.box_speeds[gen.tx.blockchain];
        gen.position += diff;
        gen.box.position.set(bpos.x, bpos.y, bpos.z + diff);
        gen.text.position.set(tpos.x, tpos.y, tpos.z + diff);
        gen.text.scale.multiplyScalar(config.text_scale[gen.tx.blockchain]);
        gen.text.scale.clamp(Vector3Zero, gen.textScale);
      })
    }
  }
}
