<template>
  <div>
    <audio autoplay muted loop ref="backgroundAudio">
      <source :src="src" type="audio/mp3" />
    </audio>

    <b-button @click="toggleMute">
      <img :src="muteImg" />
    </b-button>
  </div>
</template>

<script>
export default{
  name : 'BackgroundAudio',

  props : {
    src : {
      type : Object,
      required : true
    }
  },

  data(){
    return {
      muted : true
    }
  },

  methods : {
    toggleMute (){
      this.muted = !this.muted;
      this.$refs.backgroundAudio.muted = this.muted;

      if(!this.muted)
        this.$refs.backgroundAudio.play();
    }
  },

  computed : {
    muteImg (){
      return this.muted ?
        require("../assets/images/mute.png") :
        require("../assets/images/unmute.png");
    }
  }
}
</script>

<style scoped>
.btn-secondary{
  box-shadow: none;
  background-color: #35343c;
  border-radius: 10px;
  border: none;
  padding: 0px 5px 3px 5px;
}

img{
  width: 16px;
  height: 16px;
}
</style>
