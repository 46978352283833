//const logger = require("./logger")

function convert(tx, blockchain){
  const module = require("./convert/" + blockchain)
  return module.convert(tx)
}

function safe_convert(tx, blockchain){
  try{
    return convert(tx, blockchain)
  }catch(err){
    //logger.error("Convert Error %s\n%s", err, err.stack)
    return null;
  }
}

module.exports = {
  convert,
  safe_convert
}
