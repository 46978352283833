<template>
  <div id="tx_loader" />
</template>

<script>
import config from '../config/config'
import server_api from '../mixins/server_api'

export default {
  name: 'TXLoader',

  data(){
    return {
      loading : false,
      interval : null
    };
  },

  mixins : [server_api],

  mounted : async function() {
    await this.get_txs();

    this.interval = setInterval(async () => {
      if(this.loading) return;
      this.loading = true;

      await this.get_txs();
      this.loading = false;
    }, config.poll)
  },

  unmounted (){
    if(this.interval){
      clearInterval(this.interval)
      this.interval = null;
    }
  }
}
</script>

<style scoped>
</style>
