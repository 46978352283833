import * as Three from 'three'
import SpriteText from 'three-spritetext';

const config = require('../../config/config')
const util = require("../../util")
const txfactor_config = require('../../config/txf.actor')

const TEXT_OFFSET = new Three.Vector3(0, 20, 3);

export default {
  data(){
    return {
      last_generated : {
        xrp : {camel : {
          position : new Three.Vector3(1000, 1000, 1000),
        }, marker : 0}
      }
    }
  },

  computed : {
    txs() {
      return this.$store.state.txs;
    }
  },

  methods : {
    init_data (){
      if(!this.generated){
        this.generated = {};
        this.generated_ids = [];
      }
    },

    marker(n){
      return this.markers.find(m => m.name == "marker" + n);
    },

    should_generate(blockchain){
      if(!this.camel || !this.markers) return false;

      if(!Object.keys(this.last_generated).includes(blockchain))
        return false;
      const last = this.last_generated[blockchain];
      const max = config.camel_interval;
      let marker = new Three.Vector3();
      this.marker(last.marker).getWorldPosition(marker);
      const distance = last.camel.position.distanceTo(marker);
      return distance > max;
    },

    generate(){
      const now = new Date();

      txfactor_config.blockchains.forEach((b) => {
        if(!this.should_generate(b)) return;

        let btxs = this.txs[b];
        if(btxs.length == 0) return;

        const tx = btxs.dequeue();
        const generated = this.generate_one(tx, now);
        this.generated[tx.txf_id] = generated;
        this.generated_ids = Object.keys(this.generated);
        this.last_generated[b] = generated;
      });
    },

    generate_one(tx, now){
      let marker0 = new Three.Vector3();
      let marker1 = new Three.Vector3();
      this.marker(0).getWorldPosition(marker0);
      this.marker(1).getWorldPosition(marker1);
      let gltf = util.default.clone_gltf(this.camel);
      let camel = gltf.scene;
      camel.scale.set(25, 25, 25);
      camel.position.copy(marker0);
      camel.lookAt(marker1);

      let rig = camel.children[0];
      let mesh = rig.children[0]; 
      let mat = mesh.material;
      mat.transparent = true;
      mat.opacity = 0;
      this.scene.add(camel);

      let textContent = tx.hash.substring(0, 10);
      let text = new SpriteText(textContent, 1, 'white')
      text.backgroundColor = 'brown';
      text.padding = 3;
      text.borderRadius = 3;
      text.scale.set(25, 15, 15);
      text.position.copy(marker0);
      text.position.add(TEXT_OFFSET);
      this.scene.add(text);

      let animation = gltf.animations[0];
      let mixer = new Three.AnimationMixer(camel);
      let camelAction = mixer.clipAction(animation)
      camelAction.play();

      const generated = {
        tx,
        camel,
        text,
        mixer,
        marker: 0,
        timestamp : now
      };

      mixer.addEventListener("loop", () => {
        const marker = new Three.Vector3();
        this.marker(generated.marker+1).getWorldPosition(marker);
        marker.sub(camel.position).normalize();

        const camelPos = marker.multiplyScalar(config.camel_stride);
        camel.position.add(camelPos);

        text.position.copy(camel.position);
        text.position.add(TEXT_OFFSET);
      })

      // Return generated
      return generated;
    },

    process_generated(){
      // Last marker
      let markerN = new Three.Vector3();
      this.marker(config.camel_markers-1).getWorldPosition(markerN);

      this.generated_ids.forEach((g) => {
        let gen = this.generated[g];
        let mat = gen.camel.children[0].children[0].material;

        if(gen.fade_out){
          if(mat.opacity > 0)
            mat.opacity -= 0.01;

        }else if(mat.opacity < 1)
          mat.opacity += 0.01;

        gen.mixer.update(this.delta);

        ///

        const dist = gen.camel.position.distanceTo(markerN);
        if(dist < config.proximity){
          this.scene.remove(gen.camel);
          delete this.generated[g];
          this.generated_ids = Object.keys(this.generated);
          return;

        }else if(dist < config.proximity * 5){ 
          gen.fade_out = true;

          if(gen.text){
            this.scene.remove(gen.text);
            delete gen.text;
          }

          return;
        }

        ///

        let nextMarker = new Three.Vector3();
        this.marker(gen.marker+1).getWorldPosition(nextMarker);
        if(gen.camel.position.distanceTo(nextMarker) < config.proximity){
          gen.marker++;
          this.marker(gen.marker+1).getWorldPosition(nextMarker);
        }
        gen.camel.lookAt(nextMarker);

        let rigPos = new Three.Vector3();
        gen.camel.children[0].children[1].getWorldPosition(rigPos);
        gen.text.position.copy(rigPos).add(TEXT_OFFSET);
      })
    }
  }
}
