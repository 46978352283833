<template>
  <div id="money_flow">
    <h2>DLT Money Flow</h2>
    <svg></svg>
    <div id="currencies">
      <div v-for="currency in currencies" :key="currency">
        <input type="checkbox" :checked="!is_excluded(currency)" :id="currency" :ref="currency"
          @change="toggle_currency(currency)" />
        <label :for="currency" >{{currency}}</label>
      </div>
    </div>

    <button @click="reset_reveal">Reset</button>
    <button @click="select_none">Select none</button>
    <button @click="select_interesting">Select Interesting</button>
  </div>
</template>

<script>
import * as d3 from "d3";
const config = require('./config/config')

const reveal_delay = 50;

export default {
  data : function() {
    return {
      money_flow : {},
      colors : {},
      exclude_currencies : [],
      reveal_upto : null
    }
  },

  computed : {
    dates : function(){
      return Object.keys(this.money_flow)
               .map(d => new Date(Date.parse(d)));
    },

    currencies : function(){
      let cs = new Set();
      this.dates.forEach(d =>{
        Object.keys(this.money_flow[d.toISOString()])
          .forEach(c => cs.add(c))});
      return [...cs];
    },

    selected_currencies : function() {
      return this.currencies.filter(c => !this.exclude_currencies.includes(c));
    },

    min_date : function(){
      return new Date(Math.min.apply(null, this.dates));
    },

    max_date : function(){
      return new Date(Math.max.apply(null, this.dates));
    },

    dates_upto_reveal : function(){
      return this.dates.filter(d => d <= this.reveal_upto);
    },

    min : function(){
      let m = Number.POSITIVE_INFINITY;
      this.dates.forEach(d => {
        this.selected_currencies.forEach(c => {
          let cm = this.money_flow[d.toISOString()][c];
          if(cm && cm < m) m = cm;
        })
      })
      return m;
    },

    max : function(){
      let m = Number.NEGATIVE_INFINITY;
      this.dates.forEach(d => {
        this.selected_currencies.forEach(c => {
          let cm = this.money_flow[d.toISOString()][c];
          if(cm && cm > m) m = cm;
        })
      })
      return m;
    },
  },

  methods : {
    init_chart(){
      // SVG and graph
      this.width = 1700;
      this.height = 750;
      this.svg = d3.select("svg").attr("width", this.width).attr("height", this.height);
      this.g = this.svg.append("g");
    },

    handle_data(data){
      this.money_flow = data;
      this.reveal_upto = this.min_date;
      this.refresh_graph();
      this.start_reveal();
    },

    refresh_graph(){
      // x axis
      if(!this.x)
        this.x = d3.scaleTime();
      this.x
        .domain(d3.extent(this.dates, d => d))
        .rangeRound([0, this.width]);

      // y axis
      if(!this.y)
        this.y = d3.scaleLog();
      this.y
          .domain([this.min, this.max])
          .rangeRound([this.height, 0]);


      // Append axes
      if(!this.xAxis)
        this.xAxis = this.g.append("g")
                           .attr("transform", "translate(50," + this.height + ")")
      this.xAxis.call(d3.axisBottom(this.x));

      if(!this.yAxis){
        this.yAxis = this.g.append("g")
          .attr("transform", "translate(50,0)");
        this.yAxis
          .append("text")
          .attr("fill", "#000")
          .attr("transform", "rotate(-90)")
          .attr("y", 6)
          .attr("dy", "0.71em")
          .attr("text-anchor", "end")
          .text("Volume (absolute nominal units)");
      }
      this.yAxis.call(d3.axisLeft(this.y).ticks(10, "~s"))

      // Create series and line
      if(!this.lines)
        this.lines = {};
      if(!this.paths)
        this.paths = {};
      if(!this.text)
        this.text = {};

      const color_scale = d3.schemeSet2;
      this.selected_currencies.forEach((c,ci) => {
        const color = this.colors[c] || color_scale[ci%color_scale.length];
        this.colors[c] = color;

        let date_value = [];
        this.dates_upto_reveal.forEach(d => {
          let dt = d.toISOString();
          let dc = this.money_flow[dt][c]
          if(dc) date_value.push({
            date : d, amount : dc
          });
        })

        // Skip if we don't have enough data
        if(date_value.length < 2) return;

        if(!this.lines[c])
          this.lines[c] = d3.line();

        this.lines[c]
          .x(function (d) {
            return this.x(d.date);
          }.bind(this))
          .y(function (d) {
            return this.y(d.amount);
          }.bind(this));

        if(!this.paths[c])
          this.paths[c] = this.g.append("path")
            .attr("transform", "translate(50,0)")
            .attr("fill", "none")
            .attr("stroke", color)
            .attr("stroke-width", 1.5)

          this.paths[c]
            .datum(date_value)
            .attr("d", this.lines[c]);


        if(!this.text[c])
          this.text[c] = this.svg.append("text")
            .attr("dy", ".35em")
            .attr("text-anchor", "start")
            .style("fill", color)
            .text(c);

        this.text[c]
            .attr("transform", "translate(" + (this.x(date_value[date_value.length-1].date)+25) + "," + (this.y(date_value[date_value.length-1].amount)+25) + ")")
      })

      this.exclude_currencies.forEach(c => {
        this.remove_currency(c);
      })
    },

    toggle_currency (currency){
      if(this.is_excluded(currency))
        this.exclude_currencies.splice(this.exclude_currencies.indexOf(currency), 1)
      else
        this.exclude_currencies.push(currency);
      this.refresh_graph();
    },

    start_reveal(){
      if(this.reveral_interval)
        clearInterval(this.reveal_interval);

      this.reveal_interval = setInterval(() => {
        this.reveal_upto =
          this.dates.sort((d1, d2) => d1 - d2)
                    .find(d => d > this.reveal_upto)
        if(this.reveal_upto)
          this.refresh_graph();
      }, reveal_delay)
    },

    select_none (){
      this.exclude_currencies = [...this.currencies];
      this.reset_reveal();
    },

    reset_reveal () {
      this.reveal_upto = this.min_date;
      this.remove_all_currencies();
      this.start_reveal;
    },

    remove_all_currencies(){
      this.currencies.forEach((c) => this.remove_currency(c))
    },


    remove_currency(c){
      if(this.paths[c]){
        this.paths[c].remove()
        this.paths[c] = null;
      }

      if(this.text[c]){
        this.text[c].remove();
        this.text[c] = null;
      }
    },

    is_excluded (c) {
      return this.exclude_currencies.includes(c);
    },

    select_interesting(){
      ["ZERO", "USD", "FRL", "CSC",
       "SOLO", "Xoge", "ETH", "XLoyalitY",
       "XRPayNet", "CNY", "XCC"].forEach(c => {
        if(this.is_excluded(c))
          this.toggle_currency(c);
      })
    }
  },

  mounted() {
    this.init_chart();
    this.$htttp().get(config.money_flow, {headers : {'ngrok-skip-browser-warning' : 'True'}})
      .then((res => this.handle_data(res.body)))
  }
};
</script>

<style scoped>
svg{
  width: 1750px;
  height: 800px;
  margin: 25px;
}

#currencies{
  display: flex;
  flex-wrap: wrap;
}

#currencies div{
  flex: 1 1 160px;
}

#currencies label{
  margin-left: 5px;
}
</style>
