const SATOSHIS_PER_BTC = 1_000_000;

module.exports = {
  convert(tx){
    let converted = {
      blockchain : 'btc',
      currency : 'btc',
      type : 'payment',
      timestamp : new Date(Date.now())
    }

    converted.fee = tx.fee;
    converted.block = tx.block_height;

    converted.sender = tx.inputs[0].prev_out.addr;
    converted.recipient = tx.out[0].addr;
    converted.amount = tx.out[0].value/SATOSHIS_PER_BTC;
    converted.orig = tx;

    return converted;
  }
}
