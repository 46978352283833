import * as Three from 'three'

export default {
  methods : {
    init_input(){
      this.body = document.getElementsByTagName("body")[0];
    },

    ///

    inputRaycaster(event){
      let x = event.center ? event.center.x : event.clientX; 
      let y = event.center ? event.center.y : event.clientY; 

      let bounds = this.$refs.tx_viewer.getBoundingClientRect();

      let mouse = new Three.Vector2();
      mouse.x =  ((x-bounds.left) / bounds.width) * 2 - 1;
      mouse.y = -((y-bounds.top) / bounds.height) * 2 + 1;

      let raycaster = new Three.Raycaster();
      raycaster.setFromCamera( mouse, this.camera );
      return raycaster;
    },

    overlapsDNP(raycaster){
      return raycaster.intersectObject(this.dnpLogo).length > 0;
    },

    overlapsCamel(raycaster){
      return this.generated_ids.find(g => {
        const mesh = this.generated[g].camel.children[0].children[0];
        const text = this.generated[g].text;
        if(!mesh || !text) return false;

        return raycaster.intersectObject(mesh).length > 0 ||
               raycaster.intersectObject(text).length > 0;
      });
    },

    mouseMove(event){
      if(!this.loaded.land) return;

      let raycaster = this.inputRaycaster(event);
      if(this.overlapsDNP(raycaster) || this.overlapsCamel(raycaster)) {
        this.body.style.cursor = "pointer";

      }else {
        this.body.style.cursor = "default";
      }
    },

    click(event){
      if(!this.loaded.land) return;

      let raycaster = this.inputRaycaster(event);
      if(this.overlapsDNP(raycaster)) {
        window.open("https://devnullprod.com", '_blank');
        return;
      }

      const camel = this.overlapsCamel(raycaster);
      if(camel)
        window.open("https://xrpscan.com/tx/" + this.generated[camel].tx.hash, '_blank');
    },

    tapped(event){
      if(!this.loaded.land) return;

      let raycaster = this.inputRaycaster(event);
      if(this.overlapsDNP(raycaster)) {
        window.open("https://devnullprod.com", '_blank');
        return;
      }

      const camel = this.overlapsCamel(raycaster);
      if(camel)
        window.open("https://xrpscan.com/tx/" + this.generated[camel].tx.hash, '_blank');
    }
  }
}
