module.exports = {
  // Data poll time
  poll : 60000, // 60 seconds

  // Request timeout
  request : 3000, // 3 seconds

  // Number of boxes created ahead of time
  pool : 100,

  // Position data will be destroyed
  destroy : 25,

  // Position to trigger proximity
  proximity : 10,

  // Toggle stats visibility
  stats : false,

  // Assembly line positions (X)
  assembly_positions : {
    btc :   0,
    eth :  10,
    xrp : -10
  },

  // UV movement speeds
  uv_speeds : {
    btc : 0.01,
    eth : 0.015,
    xrp : 0.06
  },

  // Box speeds
  box_speeds : {
    btc : 0.03,
    eth : 0.045,
    xrp : 0.2
  },

  // Camel movement stride (in one animation cycle)
  camel_stride : 8,

  // Text scale multiplier
  text_scale : {
    btc : 1.005,
    eth : 1.01,
    xrp : 1.05
  },

  // Box interval (meters)
  box_interval : -20,

  // Camel interval (meters)
  camel_interval : 50,

  // Camel markers
  camel_markers : 5,

  // Network URIs
  network : {
    btc : {
      latest_block : "https://api.blockcypher.com/v1/btc/main",
      block_n : "https://blockchain.info/rawblock/HASH?cors=true"
    },

    eth : "https://cloudflare-eth.com/",
    xrp : "wss://s2.ripple.com:443"
  },

  money_flow : "https://9321-71-105-138-208.ngrok-free.app/mf",
  transactions : "https://9321-71-105-138-208.ngrok-free.app/tx",
  prices : "https://9321-71-105-138-208.ngrok-free.app/prices"
}
