module.exports = {
  convert(tx){
    let converted = {
      blockchain : 'xlm',
      timestamp : new Date(Date.now())
    }

    converted.tx = tx;
    converted.money_flow = [];
    converted.offers = [];

    // TODO: extract money flow

    const ops = tx.raw.envelope.tx.operations;
    for(let o = 0; o < ops.length; ++o){
      const op = ops[o];
      const sell = op._type == "manageSellOffer";
      const buy  = op._type == "manageBuyOffer";
      if(!sell && !buy) continue;

      const selling_xlm = typeof(op.selling) == "string";
      const buying_xlm = typeof(op.buying) == "string";

      let asset, xlm;
      if(sell){
        if(selling_xlm){
          xlm = parseInt(op.amount);
          asset = op.buying;
          asset.value = xlm * op.price;

        }else if(buying_xlm){
          asset = op.selling;
          asset.value = parseInt(op.amount)
          xlm = asset.value * op.price;
        }

      }else if(buy){
        if(buying_xlm){
          xlm = parseInt(op.buyAmount)
          asset = op.selling;
          asset.value = xlm * op.price;

        }else if(selling_xlm){
          asset = op.buying;
          asset.value = parseInt(op.buyAmount)
          xlm = asset.value * op.price;
        }
      }

      const price = asset.value / xlm;

      if(price && price > 0)
        converted.offers.push({asset, xlm, price})
    }

    return converted;
  }
}
