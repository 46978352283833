<template>
  <b-container>
    <b-row>
      <b-col>
        <h2>Arbitrage Monitor</h2>
      </b-col>
    </b-row>

    <b-row>
      <b-col><h3>XRP/USD: {{prices.xrp?.toFixed(5)}}</h3></b-col><b-col><h3>XLM/USD: {{prices.xlm?.toFixed(5)}}</h3></b-col>
    </b-row>

    <b-row>
      <b-col class="currency_col">
        <b-row v-for="(price,i) in xrp_prices" :key="'xrp' + i" :class="is_buy_sell_boundry('xrp', i) ? 'boundry' : null">
          <b-col :class="price_up('xrp', i) ? 'price_green' : price_down('xrp', i) ? 'price_red' : null">
            {{price.toFixed(5)}}
          </b-col>
        </b-row>
      </b-col>

      <b-col class="currency_col">
        <b-row v-for="(price,i) in xlm_prices" :key="'xlm' + i" :class="is_buy_sell_boundry('xlm', i) ? 'boundry' : null">
          <b-col :class="price_up('xlm', i) ? 'price_green' : price_down('xlm', i) ? 'price_red' : null">
            {{price.toFixed(5)}}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
const config = require('./config/config')

export default {
  data (){
    return {
      prices : {},
      old_prices : null,
      txs : {}
    }
  },

  computed : {
    xrp_prices(){
      return this.txs.xrp?.map(t => t.offers)
                          .flat()
                          .filter(o => o)
                          .map(o => o.price)
                          .sort((p1, p2) => p2 - p1) || []
    },

    xlm_prices(){
      return this.txs.xlm?.map(t => t.offers)
                          .flat()
                          .filter(t => t)
                          .map(o => o.price)
                          .sort((p1, p2) => p2 - p1) || []
    },

  },

  methods : {
    handle_data(data){
      this.old_prices = {xrp : this.xrp_prices, xlm : this.xlm_prices}
      this.txs = data
    },

    blockchain_prices(blockchain){
      if(blockchain == 'xrp') return this.xrp_prices;
      if(blockchain == 'xlm') return this.xlm_prices;
    },

    price_up(blockchain, i){
      if(!this.old_prices[blockchain] || this.old_prices[blockchain].length <= i) return false;
      return this.blockchain_prices(blockchain)[i] > this.old_prices[blockchain][i];
    },

    price_down(blockchain, i){
      if(!this.old_prices[blockchain] || this.old_prices[blockchain].length <= i) return false;
      return this.blockchain_prices(blockchain)[i] < this.old_prices[blockchain][i];
    },

    sync_data(){
      this.$htttp().get(config.transactions, {headers : {'ngrok-skip-browser-warning' : 'True'}})
        .then((res => this.handle_data(res.body)))
      this.$htttp().get(config.prices, {headers : {'ngrok-skip-browser-warning' : 'True'}})
        .then((res) => this.prices = res.body)
    },

    is_buy_sell_boundry (blockchain, i){
      const bp = this.blockchain_prices(blockchain);
      let b = -1;
      for(let bi = 1; bi < bp.length; ++bi){
        if(bp[bi-1] > this.prices[blockchain] && bp[bi] < this.prices[blockchain]){
          b = bi;
          break;
        }
      }
      return b == i;
    }
  },

  mounted() {
    this.sync_data();
    setInterval(() => {
      this.sync_data();
    }, 3000)
  }
}
</script>

<style scoped>
.container{
  background-color: black;
  color: white;
  max-width: unset;
}

h2, h3, .col{
  text-align: center;
}

.price_green{
  background-color: green;
}
.price_red{
  background-color: red;
}

.boundry{
  border-top: 2px solid white;
}
</style>
