const GWEI_PER_ETH = 1_000_000_000;
const WEI_PER_ETH = 1_000_000_000_000_000_000

module.exports = {
  GWEI_PER_ETH,

  convert(tx){
    // TODO: eventually dive into data (& smart contracts?)
    // to extract more specific info

    let converted = {
      blockchain : 'eth',
      currency : 'eth',
      type : 'payment',
      timestamp : new Date(Date.now())
    }

    converted.fee = parseInt(tx.gasLimit._hex || tx.gasLimit.hex)/GWEI_PER_ETH
    converted.amount = parseInt(tx.value._hex || tx.value.hex)/WEI_PER_ETH;
    converted.sender = tx.from;
    converted.recipient = tx.to;
    converted.block = tx.blockNumber;
    converted.orig = tx;

    return converted;
  }
}
