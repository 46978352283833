import * as Three from 'three'

export default {
  methods : {
    init_input(){
      this.body = document.getElementsByTagName("body")[0];
    },

    ///

    inputRaycaster(event){
      let x = event.center ? event.center.x : event.clientX; 
      let y = event.center ? event.center.y : event.clientY; 

      let bounds = this.$refs.tx_viewer.getBoundingClientRect();

      let mouse = new Three.Vector2();
      mouse.x =  ((x-bounds.left) / bounds.width) * 2 - 1;
      mouse.y = -((y-bounds.top) / bounds.height) * 2 + 1;

      let raycaster = new Three.Raycaster();
      raycaster.setFromCamera( mouse, this.camera );
      return raycaster;
    },

    overlapsDNP(raycaster){
      return raycaster.intersectObject(this.dnpLogo).length > 0;
    },

    overlapsBlockTrac(raycaster){
      return raycaster.intersectObject(this.blockTracLogo1).length > 0 ||
             raycaster.intersectObject(this.blockTracLogo2).length > 0;
    },

    ///

    mouseMove(event){
      if(!this.loaded.factory) return;

      let raycaster = this.inputRaycaster(event);
      if(this.overlapsDNP(raycaster) || this.overlapsBlockTrac(raycaster)) {
        this.body.style.cursor = "pointer";

      }else {
        this.body.style.cursor = "default";
      }
    },

    click(event){
      if(!this.loaded.factory) return;

      let raycaster = this.inputRaycaster(event);
      if(this.overlapsDNP(raycaster)) {
        window.open("https://devnullprod.com", '_blank');

      }else if(this.overlapsBlockTrac(raycaster)){
        window.open("https://blocktr.ac", '_blank');
      }
    },

    tapped(event){
      if(!this.loaded.factory) return;

      let raycaster = this.inputRaycaster(event);
      if(this.overlapsDNP(raycaster)) {
        window.open("https://devnullprod.com", '_blank');

      }else if(this.overlapsBlockTrac(raycaster)){
        window.open("https://blocktr.ac", '_blank');
      }
    }
  }
}
