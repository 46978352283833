<template>
  <b-modal id="swell_about_modal"
           ref="swell_about_modal"
           ok-title="Close"
           ok-only
           size="xl"
           centered
           hide-header>
    <ModalWrapper>
    <div style="text-align: center">
      <u><a href="https://devnullprod.com" target="_blank">Dev Null Productions</a> @ <b>Ripple</b> <i>Swell</i></u><br/>
      Dubai UAE, 2023
    </div>
    <br/>

    <div>
      For thousands of years merchants transported goods and wares of value via <u>camel</u> across the Arabian Peninsula. For that reason, the camel has become a symbol synonymous with trade and economy.
    </div>
    <br/>

    <div>
      While these days <b>Value</b> is moved via the <b>Internet</b>, <i>Dev Null Productions</i> knows how important it is to represent and visualize data in a meaningful and interesting manner.
    </div>
    <br/>

    <div>
      Here we see live <b>XRPL</b> transactions of interest. Click any particular transaction to view its details.
    </div>
    </ModalWrapper>
  </b-modal>
</template>

<script>
import ModalWrapper from './ModalWrapper'

export default{
  name : "SwellAboutModal",

  inject : ["mq"],

  components : {
    ModalWrapper
  },

  computed : {
    mobile(){
      return ['xs', 'sm', 'md'].includes(this.mq.current)
    },

    desktop(){
      return !this.mobile;
    }
  }
}
</script>

<style>
#about_modal {
  text-align: center;
  font-weight: bold;
  font-size: 22px;
}

#about_modal___BV_modal_content_{
  border-radius: 25px;
}

#about_modal___BV_modal_header_{
  display: none;
}

#about_modal___BV_modal_body_{
  padding-top: 25px;
}

#about_modal___BV_modal_body_ img{
  margin-bottom: 25px;
}

#about_modal___BV_modal_footer_{
  border: none;
}

#about_modal___BV_modal_footer_ button{
  width: 100%;
  font-weight: bold;
}
</style>
