import {GLTFLoader} from 'three/addons/loaders/GLTFLoader.js'
import { FontLoader } from 'three/addons/loaders/FontLoader.js'
import * as Three from 'three'

const config = require('../../config/config')
const util = require("../../util")
const random_item = util.default.random_item;
const Queue = require("../../queue").Queue;

export default {
  data(){
    return {
      loaded : {
        assembly : false,
        boxes : false,
        factory : false,
        font : false,
        person1 : false,
        person2 : false
      }
    }
  },

  computed : {
    loading_msg(){
      return this.$store.state.loading;
    },

    is_loaded(){
      return this.loaded.assembly && this.loaded.boxes &&
             this.loaded.factory && this.loaded.font &&
             this.loaded.person1 && this.loaded.person2;
    },
  },

  methods : {
    // Load assembly model
    load_assembly (){
      if(this.loaded.assembly) return;

      const gltf_asset = require('@/assets/blender/assembly.glb');
      const gltfLoader = new GLTFLoader();
      gltfLoader.load(gltf_asset,
        (gltf) => {
          let model = gltf.scene.children[0];
          this.assembly = model;

          this.btc_model = model.clone();
          this.eth_model = model.clone();
          this.xrp_model = model.clone();

          this.btc_model.material = model.material.clone();
          this.btc_model.material.map = model.material.map.clone();
          this.eth_model.material = model.material.clone();
          this.eth_model.material.map = model.material.map.clone();
          this.xrp_model.material = model.material.clone();
          this.xrp_model.material.map = model.material.map.clone();

          this.btc_model.position.set(config.assembly_positions.btc, 0.5, -5);
          this.eth_model.position.set(config.assembly_positions.eth, 0.5, -5);
          this.xrp_model.position.set(config.assembly_positions.xrp, 0.5, -5);

          this.scene.add(this.btc_model);
          this.scene.add(this.eth_model);
          this.scene.add(this.xrp_model);
          this.$store.commit('set_loading', 'Loaded Assembly...');
          this.loaded.assembly = true;
        }
      );
    },

    // Load box model
    load_boxes (){
      if(this.loaded.boxes) return;
      this.boxes = new Queue();

      const gltf_asset = require('@/assets/blender/boxes.glb')
      const gltfLoader = new GLTFLoader();
      gltfLoader.load(gltf_asset,
        (gltf) => {
          for(let b = 0; b < config.pool; ++b){
            const box = random_item(gltf.scene.children).clone();
            box.position.set(-1000, -1000, -1000);
            this.scene.add(box);
            this.boxes.enqueue(box);
          }

          this.$store.commit('set_loading', 'Loaded Boxes...');
          this.loaded.boxes = true;
        }
      );
    },

    // Load factory model
    load_factory () {
      if(this.loaded.factory) return;

      const gltf_asset = require('@/assets/blender/factory2.glb')
      const gltfLoader = new GLTFLoader();
      gltfLoader.load(gltf_asset,
        (gltf) => {
          this.factory = gltf.scene;
          this.scene.add(this.factory);

          for(let c = 0; c < this.factory.children.length; ++c){
            let child = this.factory.children[c];
            let name = child.name;

            if(name == 'DNPLogo')
              this.dnpLogo = child;
            else if(name == 'BlockTrac1')
              this.blockTracLogo1 = child;
            else if(name == 'BlockTrac2')
              this.blockTracLogo2 = child;
          }

          this.$store.commit('set_loading', 'Loaded Factory...');
          this.loaded.factory = true;
      })
    },

    // Load font
    load_font () {
      if(this.loaded.font) return;

      let loader = new FontLoader();
      this.font = loader.parse(require('@/assets/fonts/Lora_Regular.json'))
      this.$store.commit('set_loading', 'Loaded Font...');
      this.loaded.font = true;
    },

    // Load person 1
    load_person1(){
      if(this.loaded.person1) return;

      const glb_asset = require('@/assets/blender/person3.glb')
      const glbLoader = new GLTFLoader();
      glbLoader.load(glb_asset,
        (glb) => {
          this.person1 = glb.scene;
          this.person1.position.set(-2, 9, -28);
          this.person1.scale.set(2.15, 2.15, 2.15);
          this.person1.rotation.set(0, 45, 0);
          this.scene.add(this.person1);

          let animation = glb.animations[0];
          this.person1Mixer = new Three.AnimationMixer(this.person1);
          this.person1Action = this.person1Mixer.clipAction(animation)
          this.person1Action.play();

          this.$store.commit('set_loading', 'Loaded Animation 1...');
          this.loaded.person1 = true;
      })
    },

    load_person2(){
      if(this.loaded.person2) return;

      const glb_asset = require('@/assets/blender/person4.glb')
      const glbLoader = new GLTFLoader();
      glbLoader.load(glb_asset,
        (glb) => {
          this.person2 = glb.scene;
          this.person2.position.set(2, 9, -28);
          this.person2.scale.set(2.15, 2.15, 2.15);
          this.person2.rotation.set(0, -45, 0);

          let animation = glb.animations[0];
          this.person2Mixer = new Three.AnimationMixer(this.person2);
          this.person2Action = this.person2Mixer.clipAction(animation)
          this.person2Action.play();

          this.$store.commit('set_loading', 'Loaded Animation 2...');
          this.scene.add(this.person2);
          this.loaded.person2 = true;
      })
    },

    // Load all constructs
    load (){
      this.load_assembly();
      this.load_boxes();
      this.load_factory();
      this.load_font();
      this.load_person1();
      this.load_person2();
    }
  }
}
